import './Footer.css'

function Footer(){
    return (
        <div className={'footer-container'}>

           ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ «РОССИЙСКИЙ НАУЧНО-ИССЛЕДОВАТЕЛЬСКИЙ ИНСТИТУТ КОМПЛЕКСНОГО ИСПОЛЬЗОВАНИЯ И ОХРАНЫ ВОДНЫХ РЕСУРСОВ» (ФГБУ РОСНИИВХ)
            <br/>
                <br/>344037, г. Ростов-на-Дону, ул. Ченцова, здание ЗД. 10А,   Телефон: (863) 285-30-24<br/>
Дальневосточный филиал ФГБУ РосНИИВХ: 690002, г. Владивосток, ул Некрасовская, д. 53б, Телефон: (423) 245-67-98<br/>
                                      Копирование и распространение ресурсов сайта запрещено <br/><br/>
        </div>
    );
}

export default Footer;

