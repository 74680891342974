export default function ContactsPage(){
    return(
        <div>
            <ul>
                <li>
                    ФГБУ «РОССИЙСКИЙ НАУЧНО-ИССЛЕДОВАТЕЛЬСКИЙ ИНСТИТУТ КОМПЛЕКСНОГО ИСПОЛЬЗОВАНИЯ И ОХРАНЫ ВОДНЫХ РЕСУРСОВ» (ФГБУ РОСНИИВХ)<br/>
                    <a href={'https://wrm.ru/'} rel="nofollow noopener noreferrer">https://wrm.ru/</a>
                </li>
                <li>
                    ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ НАУКИ ИНСТИТУТ ВОДНЫХ ПРОБЛЕМ РОССИЙСКОЙ АКАДЕМИИ НАУК (ИВП РАН)<br/>
                    <a href={'https://iwp.ru/'} rel="nofollow noopener noreferrer">https://iwp.ru/</a>
                </li>
                <li>
                    Амурское бассейновое водное управление Росводресурсов<br/>
                    <a href={'http://amurbvu.ru/'} rel="nofollow noopener noreferrer">http://amurbvu.ru/</a>
                </li>
                <li>
                    ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ Дальневосточное УГМС<br/>
                    <a href={'https://khabmeteo.ru/'} rel="nofollow noopener noreferrer">https://khabmeteo.ru/</a>
                </li>
            </ul>
        </div>
    );
}