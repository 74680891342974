import logo from './logo.svg';
import './App.css';
import LocalHeader from './components/Layout/Header/Header'
import LocalFooter from './components/Layout/Footer/Footer'
import {Layout} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import {Route, Routes} from "react-router-dom";
import DataTable from "./components/DataTables/DataTable/DataTable";
//import 'antd/dist/antd.css';
import InflowDataTable from "./components/DataTables/InflowDataTable/InflowDataTable";
import ForecastDataTable from "./components/DataTables/ForecastDataTable/ForecastDataTable";
import BalanceDataTable from "./components/DataTables/BalanceDataTable/BalanceDataTable";
import LoginForm from "./components/LoginFormA/LoginForm";
import RequireAuth from "./components/Auth/RequireAuth";
import StaticPage from "./components/StaticPage/StaticPage";
import DispatchChart from "./components/DispatchChart2/DispatchChart";
import LoadData from "./components/LoadData/LoadData"
import MeteoDataTable from "./components/DataTables/DataTable/MeteoDataTable"


function App() {
    const {Header, Footer, Sider, Content} = Layout;
    return (
        <Layout className={'layout__full-height'}>
            <Header className={'header-container'} key={'main-header'}>
                <PageHeader
                    title={<h1 className={'h1'}>АВТОМАТИЗИРОВАННАЯ СИСТЕМА ГИДРОЛОГИЧЕСКОГО МОДЕЛИРОВАНИЯ В БАССЕЙНЕ Р.
                        АМУР</h1>}/>
            </Header>

            <Layout>
                <Header className={'header-container'} key={'second-header'}>
                    <LocalHeader/>
                </Header>
                <Content className={'main-container'} key={'main-container'}>
                    <Routes>
                        <Route path={'/'} element={
                            <RequireAuth>
                                <DataTable/>
                            </RequireAuth>}/>
                        <Route path={'observation'} element={
                            <RequireAuth>
                                <DataTable/>
                            </RequireAuth>}/>
                        <Route path={'observation/:varidparam'} element={
                            <RequireAuth>
                                <MeteoDataTable/>
                            </RequireAuth>}/>
                        <Route path={'forecast'} element={
                            <RequireAuth>
                                <ForecastDataTable/>
                            </RequireAuth>}/>
                        <Route path={'forecast-inflow/:siteCode'} element={
                            <RequireAuth>
                                <InflowDataTable/>
                            </RequireAuth>}/> 
                        <Route path={'reservoir/:siteCode'} element={
                            <RequireAuth>
                                <DispatchChart/>
                            </RequireAuth>}
                        />
                        <Route path={'static/:pname'} element={<StaticPage/>}/>
                        <Route path={'dispatchChart'} element={<DispatchChart/>}/>
                        <Route path={'loaddata/:siteCode'} element={<LoadData/>}/>
                        <Route path={'login'} element={<LoginForm/>}/>
                        {/*<Route path="*" component={NoMatch} status={404}/>*/}
                    </Routes>
                </Content>
                <Footer key={'footer-container'}><LocalFooter/></Footer>
            </Layout>

        </Layout>
    );
}

export default App;
