import { DatePicker, Table, Typography, Collapse } from "antd";
//import dataSource from './data.json'
import './InflowDataTable.css'
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import dayjs from "dayjs"
//import moment from "moment";
const { Title } = Typography;
const { Panel } = Collapse;

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)


function InflowDataTable() {

    const [dataSource, setDataSource] = useState(null);
    const [currentSiteCode, setSiteCode] = useState(0);
    const [currentDate, setDate] = useState(0);
    const { siteCode } = useParams();
    const user = useSelector((state) => state.user.user);
    const [editEnabled, setEditEnabled] = useState(false);
    const [lineSeries, setLineSeries] = useState([]);

    const options = {
        annotations: {

        },

        chart: {

            //type: 'rangeArea'
        },
        //colors: ['#C00000', '#258420', '#180395'],//, ...reg_series.map(s=>("#ccc"))],
        // colors: ['#e0eac7', '#65912a', '#a8f0ad', '#009200',
        //     '#eba8f0', '#fef200', '#ff2200', '#009200',
        //     '#eba8f0', '#a8f0ad', '#e0eac7', '#c7bfe6'],
        stroke: {
            width: 2,
            curve: 'straight'
        },



        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid'
        },
        grid: {
            show: true,
            position: 'front',
            borderColor: '#ccc',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        forecastDataPoints: {
            // count: fore?.length
        },
        yaxis: [



        ],
        xaxis: {
            type: "datetime",
            labels: {
                format: 'dd/MM',
            }
        }

    }

    useEffect(() => {
        if (user?.roles) {
            setEditEnabled(user?.roles?.includes('edit'));
        }
        else {
            setEditEnabled(false);
        }

    }, [user]);

    let date = '';
    useEffect(() => {
        if (siteCode !== currentSiteCode) {
            // console.log('setSiteCode', siteCode)
            setSiteCode(siteCode);
            //setDataSource(null);
        }
    },
        [siteCode, currentSiteCode, currentDate])

    useEffect(() => {
        document.title = "АСГДМ Амур - Прогноз притока";
        if (!currentDate) {
            // console.log('setDate');
            setDate('0');
        }
        setDataSource(null);
        fetch(`http://api.amur-hdm.ru/react/api/PixelForecasts/${siteCode}/${currentDate}`).then((response) => {
        //fetch(`http://localhost:8085/api/PixelForecasts/${siteCode}/${currentDate}`).then((response) => {
            // console.log('fetched');
            return response.json();
        })
            .then((body) => {
                setDataSource(body);
            })
    }
        , [siteCode]);


    const columns = [
        {
            title: 'Дата',
            dataIndex: 'dta',
            key: 'dta',
            fixed: 'left',
            width: '100px',
            className: 'table__column'
        },
        {
            title: 'Факт',
            dataIndex: 'obs',
            key: 'obs',
            fixed: 'left',
            width: '100px',
            className: 'table__column'
        }];
    dataSource?.Item1.forEach(dt => {
        columns.push({
            "title": 'Прогноз от ' + dt,
            "dataIndex": dt,
            "key": dt,
            "width": '82px',
            "className": 'table__column'
        })
    });

    const onDateChange = (date, dateString) => {
        const dt = date.format('YYYYMMDD');
        // console.log(dt)
        if (currentDate !== dt) {
            setDataSource(null);
        }
        setDate(dt);
    }

    useEffect(() => {
        if (dataSource) {
            const obsSeries = {
                name: "Факт", data: dataSource.Item2.map((item) => (
                    {
                        x: dayjs.utc(item.dta + '.2023','DD.MM.YYYY').toDate(),
                        y: item.obs === '-' ? null : item.obs
                    }))
            }
            console.log('obsSeries: ', obsSeries)
            const foreSeries = dataSource.Item1.map((dtIni)=>(
                {
                    name: "Прогноз от " + dtIni,
                    data: dataSource.Item2.map((item)=> (
                        {
                            x:dayjs.utc(item.dta + '.2023','DD.MM.YYYY').toDate(),
                            y: item[dtIni]? item[dtIni]:null
                        }
                    ))
                }
            ))
            setLineSeries([obsSeries,...foreSeries])
            console.log('foreSeries: ', foreSeries)
        }
    }, [dataSource])


    return (
        editEnabled ?
            <div>
                <Title level={3}>Прогноз притока к {siteCode === "ZRT" ? "Зейскому" : "Бурейскому"} водохранилищу</Title>
                <Table dataSource={dataSource?.Item2} columns={columns}
                    pagination={{ defaultPageSize: 30 }} />
                <Collapse>
                    <Panel header="График" key="1">
                        <Chart
                            series={lineSeries}
                            type="line"
                            options={options}
                            height={600}
                        />
                    </Panel>
                </Collapse>

            </div> : <></>

    );
}

export default InflowDataTable;