import dayjs from "dayjs"
//import Moment from "moment";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Typography, Table, Collapse } from 'antd';
import './DispatchChart.css'

//import data from './data.json'
//import fore from './fore.json'
import reg from './zeya_reg.json'
import h_w_z from './zeya_h_w.json'
import w_h_z from './zeya_w_h.json'
import h_w_b from './bur_h_w.json'
import w_h_b from './bur_w_h.json'
import rezParams from '../../static/rezParams.json'

const { Title } = Typography;

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const { Panel } = Collapse;

//const h_w_dict = Object.assign({}, ...h_w.map((x) => ({ [x.H]: x.W })));
//const w_h_dict = Object.assign({}, ...w_h.map((x) => ({ [x.W]: x.H })));

function DispatchChart() {

    //const [lastValue, setLastValue] = useState({ dt: '', h: 0, q_in: 0, q_out: 0, w: 0, ew: 0, ew2: 0, ew3: 0 })
    //const [store, setStore] = useState({ items: [], reg_items: [], fore_items: [], year: 2022 })
    //const [fore_data, setForeData] = useState([])
    const [data, setData] = useState()
    const [fore, setFore] = useState()
    const [maxDts, setMaxDts] = useState()
    const [lastObsDate, setLastObsDate] = useState(null)
    const [lineSeries, setLineSeries] = useState([]);
    
    //const [foreLength, setForeLength] = useState(0);

    let { siteCode } = useParams();
    const [currentSiteCode, setCurrentSiteCode] = useState("ZR")
    const EditableContext = createContext(null);

    const year = 2024

    const h_w_dict = currentSiteCode === "ZR" ? Object.assign({}, ...h_w_z.map((x) => ({ [x.H]: x.W }))) : Object.assign({}, ...h_w_b.map((x) => ({ [x.H]: x.W })))
    const w_h_dict = currentSiteCode === "ZR" ? Object.assign({}, ...w_h_z.map((x) => ({ [x.W]: x.H }))) : Object.assign({}, ...w_h_b.map((x) => ({ [x.W]: x.H })))

    const zone1_data = reg.filter(item => item.line_2).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [299, item.line_2] }))
    const zone2_data = reg.filter(item => item.line_2).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_2, item.line_3] }))
    const zone3_data = reg.filter(item => item.line_4).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_3, item.line_4] }))
    const zone4_data = reg.filter(item => item.line_4).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_4, item.line_5] }))
    const zone5_data = reg.filter(item => item.line_6).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_5, item.line_6] }))
    const zone6_data = reg.filter(item => item.line_7).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_6, item.line_7] }))
    const zone7_data = reg.filter(item => item.line_8).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_7, item.line_8] }))
    const zone8_data = reg.filter(item => item.line_9).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_10, item.line_9] }))
    const zone9_data = reg.filter(item => item.line_11).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_11, item.line_10] }))
    const zone10_data = reg.filter(item => item.line_12).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [item.line_12, item.line_11 ? item.line_11 : item.line_12] }))
    const zone11_data = reg.filter(item => item.line_12).map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [299, item.line_12 ? item.line_12 : 299] }))
    const zone12_data = reg.map((item) => ({ x: new Date(item.month < 5 ? year + 1 : year, item.month - 1, item.day), y: [298, 299] }))

    //console.log(zone10_data)
    const reg_series = [
        { name: "Зона 1", data: zone1_data },
        { name: "Зона 2", data: zone2_data },
        { name: "Зона 3", data: zone3_data },
        { name: "Зона 4", data: zone4_data },
        { name: "Зона 5", data: zone5_data },
        { name: "Зона 6", data: zone6_data },
        { name: "Зона 7", data: zone7_data },
        { name: "Зона 8", data: zone8_data },
        { name: "Зона 9", data: zone9_data },
        { name: "Зона 10", data: zone10_data },
        { name: "Зона 11", data: zone11_data },
        { name: "Зона 12", data: zone12_data },
    ]

    const options = {
        annotations: {
            yaxis: [
                {
                    y: siteCode === "ZR" ? rezParams.ZR.sink.level : rezParams.BR.sink.level,
                    yAxisIndex: 2,
                    borderColor: "#4472C4",
                    strokeDashArray: 5,
                    label: {
                        borderColor: "#4472C4",
                        style: {
                            color: "#fff",
                            background: "#4472C4"
                        },
                        text: siteCode === "ZR" ? rezParams.ZR.sink.text : rezParams.BR.sink.text
                    }
                },
                {
                    y: siteCode === "ZR" ? rezParams.ZR.umo.level : rezParams.BR.umo.level,
                    yAxisIndex: 2,
                    borderColor: "#0D0D0D",
                    strokeDashArray: 5,
                    label: {
                        borderColor: "#0D0D0D",
                        style: {
                            color: "#fff",
                            background: "#0D0D0D"
                        },
                        text: siteCode === "ZR" ? rezParams.ZR.umo.text : rezParams.BR.umo.text
                    }
                }
                ,
                {
                    y: siteCode === "ZR" ? rezParams.ZR.npu.level : rezParams.BR.npu.level,
                    yAxisIndex: 2,
                    borderColor: "#385723",
                    strokeDashArray: 5,
                    label: {
                        borderColor: "#385723",
                        style: {
                            color: "#fff",
                            background: "#385723"
                        },
                        text: siteCode === "ZR" ? rezParams.ZR.npu.text : rezParams.BR.npu.text
                    }
                }
                ,
                {
                    y: siteCode === "ZR" ? rezParams.ZR.fpu.level : rezParams.BR.fpu.level,
                    yAxisIndex: 2,
                    borderColor: "#C55A11",
                    strokeDashArray: 5,
                    label: {
                        borderColor: "#C55A11",
                        style: {
                            color: "#fff",
                            background: "#C55A11"
                        },
                        text: siteCode === "ZR" ? rezParams.ZR.fpu.text : rezParams.BR.fpu.text
                    }
                }
                ,
                {
                    y: siteCode === "ZR" ? rezParams.ZR.upps.level : -100,
                    yAxisIndex: 2,
                    borderColor: "#009900",
                    strokeDashArray: 5,
                    label: {
                        borderColor: "#009900",
                        style: {
                            color: "#fff",
                            background: "#009900"
                        },
                        text: siteCode === "ZR" ? rezParams.ZR.upps.text : ""
                    }
                }

            ],
        },

        chart: {

            //type: 'rangeArea'
        },
        colors: ['#C00000', '#258420', '#180395', ...reg_series.map(s => ("#ccc"))],
        // colors: ['#e0eac7', '#65912a', '#a8f0ad', '#009200',
        //     '#eba8f0', '#fef200', '#ff2200', '#009200',
        //     '#eba8f0', '#a8f0ad', '#e0eac7', '#c7bfe6'],
        stroke: {
            width: 2,
            curve: 'straight'
        },
        // title: {
        //     text: siteCode === "ZR" ? rezParams.ZR.title : rezParams.BR.title,//'Диспетчесрский график Зейского водохранилища',
        //     align: 'center'
        // },
        tooltip: {
            enabledOnSeries: [0, 1, 2],
            shared: true
        },

        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid'
        },
        grid: {
            show: true,
            position: 'front',
            borderColor: '#ccc',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        forecastDataPoints: {
            count: fore?.length
        },
        yaxis: [
            {
                // seriesName : "Приток",
                //opposite: true,
                min: 0,
                max: 15000,
                labels: {
                    /**
                    * Allows users to apply a custom formatter function to yaxis labels.
                    *
                    * @param { String } value - The generated value of the y-axis tick
                    * @param { index } index of the tick / currently executing iteration in yaxis labels array
                    */
                    formatter: function (val, index) {
                        return val.toFixed();
                    }
                },
                title: {
                    text: "Расход, м3/сек "
                }
            }, {
                show: false,
                min: 0,
                max: 15000,
                labels: {
                    /**
                    * Allows users to apply a custom formatter function to yaxis labels.
                    *
                    * @param { String } value - The generated value of the y-axis tick
                    * @param { index } index of the tick / currently executing iteration in yaxis labels array
                    */
                    formatter: function (val, index) {
                        return val?.toFixed();
                    }
                }
            },
            {
                // seriesName : "Уровень",
                opposite: true,
                min: siteCode === "ZR" ? 298 : 224,
                max: siteCode === "ZR" ? 323 : 264,
                //tickAmount: 25,
                labels: {
                    /**
                    * Allows users to apply a custom formatter function to yaxis labels.
                    *
                    * @param { String } value - The generated value of the y-axis tick
                    * @param { index } index of the tick / currently executing iteration in yaxis labels array
                    */
                    formatter: function (val, index) {
                        return val?.toFixed(2);
                    }
                },
                title: {
                    text: "Уровень водохранилища, мБС "
                }
                // axisTicks: {
                //     show: true,
                // },
                //orceNiceScale:true

            },
            //...reg_series.map(s=>({show: false,min: siteCode === "ZR" ? 298 : 224,max: siteCode === "ZR" ? 323 : 264}))


        ],
        xaxis: {
            type: "datetime",
            labels: {
                //format: 'dd/MM',
            }
        }
    }


    useEffect(() => {
        // console.log('1 siteCode !== currentSiteCode)', siteCode, currentSiteCode)
        if (siteCode !== currentSiteCode) {
            // console.log('setSiteCode', siteCode)
            setCurrentSiteCode(siteCode);
            // console.log('1 set fore and data null')
            setFore(null);
            setData(null);
        }
    },
        [siteCode])

    useEffect(() => {
        //console.log('2 useEffect currentSiteCode fetch')
        /// console.log('2 data: ', data)
        // console.log('2 fore: ', fore)
        const year = 2024
        //const items = data.map(i => [new Moment(i.date, "DD.MM.YYYY").toDate().getTime(), i.h, i.q_out, i.q_in, h_w_dict[i.h]]);
        if (!data && siteCode) {
            fetch(`http://api.amur-hdm.ru/react/api/ReservoirValues/graph/${currentSiteCode}`).then((response) => {
                //fetch(`http://localhost:8085/api/ReservoirValues/graph/${currentSiteCode}`).then((response) => {
                //fetch(`http://localhost:8085/api/PixelForecasts/lastemg`).then((response) => {
                // console.log('fetched');
                return response.json();
            })
                .then((body) => {
                    // console.log(body)

                    // console.log('2 setData')
                    setLastObsDate(body.Item1);
                    setData(body.Item2);
                    //console.log('2 data: ', data)

                })
        }

    }, [currentSiteCode])

    // useEffect(() => {
    //     console.log('useEffect sitecode null')
    //     console.log('data: ', data)
    //     console.log('fore: ', fore)
    //     console.log('setData null')

    //     setData(null);
    //     setFore(null);
    //     console.log('data: ', data)

    // }, [siteCode])

    useEffect(() => {
        // console.log('3 useEffect lastObsDate ', lastObsDate)
        if (lastObsDate) {
            fetch(`http://api.amur-hdm.ru/react/api/ReservoirValues/graph-fore/${currentSiteCode}/${lastObsDate}`).then((response) => {
           // fetch(`http://localhost:8085/api/ReservoirValues/graph-fore/${currentSiteCode}/${lastObsDate}`).then((response) => {
                //fetch(`http://localhost:8085/api/PixelForecasts/lastemg`).then((response) => {
                // console.log('fetched');
                return response.json();
            })
                .then((body) => {
                    // console.log(dataSource)

                    // console.log('setDataSource')
                    // console.log('3 setFore')
                    setMaxDts(body.Item1)
                    const newFore = body.Item2.map(({date,q_in,q_out}) => ({date:dayjs(date).format('YYYY-MM-DD'),q_in:data[data.length-1].q_in,q_out:data[data.length-1].q_out}))
                    console.log( body.Item2)
                    console.log(newFore)
                    setFore(newFore)
                    //setFore(body.Item2)

                    //console.log('3 fore: ', fore)

                })
        }
    },
        [data]
    )

    useEffect(() => {
        //console.log('4 useEffect  data fore')
        //console.log('4 data: ', data)
        // console.log('4 fore: ', fore)
        //setLineSeries(null)
        if (data && fore) {
            //console.log('4 lastObsDate', lastObsDate)

            const year = 2024
            //const items = data.map(i => [new Moment(i.date, "DD.MM.YYYY").toDate().getTime(), i.h, i.q_out, i.q_in, h_w_dict[i.h]]);

            const levelData = data.map(i => ({ x: dayjs.utc(i.date), y: i.h === "NaN" ? null : i.h }));
            const q_outData = data.map(i => ({ x: dayjs.utc(i.date).toDate().getTime(), y: i.q_out === "NaN" ? null : i.q_out }));
            const q_inData = data.map(i => ({ x: dayjs.utc(i.date).toDate().getTime(), y: i.q_in }));
            let last_w = h_w_dict[levelData[levelData.length - 1].y]
            //console.log('4 last_w', last_w)


            const fore_q_outData = fore.map(i => ({ x: dayjs.utc(i.date).toDate().getTime(), y: i.q_out }));
            const fore_q_inData = fore.map(i => ({ x: dayjs.utc(i.date).toDate().getTime(), y: i.q_in }));
            //console.log('fore : ', fore)
            //console.log('fore_q_inData : ', fore_q_inData)

            let fore_items = fore.map(i => ({
                dt: dayjs.utc(i.date).toDate().getTime(),
                q_out: i.q_out,
                q_in: i.q_in,  //2
                dw: (Number(i.q_in) - Number(i.q_out)) * 86400 / 10 ** 9, //dw 3
                w: 0, // w 4
                h: 0 // h 5
            })
            )

            //console.log('4 fore_items: ', fore_items)

            for (let fore_item of fore_items) {
                fore_item.w = Number((Number(last_w + fore_item.dw)).toFixed(2))
                last_w = fore_item.w
                fore_item.h = w_h_dict[last_w]
            }
            //console.log('w_h_dict: ', w_h_dict)
            //console.log('4 fore_items filled: ', fore_items)

            const fore_levelData = fore_items.map(i => ({ x: i.dt, y: i.h === undefined ? null : i.h }))
            //console.log('4 fore_levelData: ', fore_levelData)


            //setForeLength(fore.length)

            const lSeries = [


                { name: "Расход", data: q_outData.concat(fore_q_outData) },
                { name: "Приток", data: q_inData.concat(fore_q_inData) },
                { name: "Уровень", data: levelData.concat(fore_levelData) },
                //...reg_series, 
            ]


            setLineSeries(lSeries);

            //console.log('4 lSeries: ', lSeries)
        }



    }, [fore])

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();

                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const defaultColumns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            width: '200px',

        },
        {
            title: 'Прогноз притока',
            dataIndex: 'q_in',
            width: 200,
            editable: true,
        },
        {
            title: 'Ожидаемый расход',
            dataIndex: 'q_out',
            width: 200,
            editable: true,
        },
        ,
    ];

    const handleSave = (row) => {
        const newData = [...fore];
        const index = newData.findIndex((item) => row.date === item.date);
        console.log('new data :', newData)
        console.log('row', row)
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setFore(newData);
    };



    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });


    return (
        <>
            <Title level={3}>{siteCode === "ZR" ? rezParams.ZR.title : rezParams.BR.title}</Title>
            {/*<Title level={4}>Прогноз притока расчитан {dayjs(maxDts).format('DD.MM.YYYY')} по модели ЭКОМАГ с использованием метеопрогноза глобальной модели GFS от {dayjs(maxDts).add(-12, 'hour').format('DD.MM.YYYY HH:mm')} UTC</Title>*/}
            <div className="wrapper">
                <Chart
                    series={lineSeries}
                    type="line"
                    options={options}
                    height={600}
                />
                <br />
                <Collapse>
                    <Panel header="Редактировать прогноз" key="1">
                        <Table
                            pagination={{ position: ['none', 'none'], defaultPageSize: 100 }}
                            components={components}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={fore}
                            columns={columns}
                        />
                    </Panel>
                </Collapse>

            </div>
        </>
    );
}

export default DispatchChart;

