import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const RequireAuth = ({children}) => {
    const location = useLocation();
    const {user} = useSelector((state) =>state.user);
    //console.log('reqUath auth user: ', user)
    //console.log('reqUathuser.login: ', user.login )
    if (!user?.login) {
        return <Navigate to='/login' state={{from: location}} />
    }

    return children;
}

export default RequireAuth;