import {useParams} from "react-router-dom";
import AboutPage from "../../static/Aboutpage";
import ContactsPage from "../../static/ContactsPage";


function StaticPage() {
    const {pname} = useParams();


    switch (pname) {
        case 'about': {
            document.title = "АСГДМ Амур - О проекте";
            return (<AboutPage/>);
            break;
        }
        case 'contacts': {
            document.title = "АСГДМ Амур - Контакты";
            return (<ContactsPage/>);
            break;
        }
        default:
            return (<div></div>);
    }


}

export default StaticPage;