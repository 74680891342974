import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const getUser = createAsyncThunk(
    'user/login',
    async ({login, password, needRemember}) => {

        const storedUserStr = localStorage.getItem('user');
        //console.log('stored user:',storedUserStr)
        if (storedUserStr) {
            const storedUser = JSON.parse(storedUserStr);
            if (storedUser.login === login) {
                //console.log('return strored', storedUser);
                return storedUser;
            } else {
                localStorage.removeItem('user');
            }
        }


            const res = await fetch('http://api.amur-hdm.ru/react/api/User/Login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({Login: login, Password: password}) // body data type must match "Content-Type" header
            }).then((data) => data.json());

            if (needRemember) {
                localStorage.setItem('user', JSON.stringify(res));
            }
        //console.log('return fetched', res);
        return res;
    }
);


export const userSlice = createSlice({
        name: 'user',
        initialState: {
            user: JSON.parse(localStorage.getItem('user'))
        },
        reducers: {
            logout: (state) => {
                state.user = {};
            }
        },
        extraReducers: {
            [getUser.fulfilled]: (state, {payload}) => {
                state.user = payload;
            }
        }
    }
);

export default userSlice.reducer;
export {getUser};
export const {logout} = userSlice.actions;