import { Table, Typography } from "antd";
//import dataSource from './data.json'
import graphAttr from '../../../static/graphAttr.json'
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './DataTable.css'
import Modal from "antd/es/modal/Modal";
import {
    LineChart, Line, XAxis, YAxis,
    CartesianGrid, Tooltip, ReferenceLine
} from 'recharts';

const { Title } = Typography;

const varNames = { 23: "Сумма осадков, мм",
                    10: "Температура воздуха, град Цельсия",
                    82: "Дефицит насыщения водяного пара, гПа"
}
//http://api.amur-hdm.ru/react/api/DataValue/last


function MeteoDataTable() {

    const [dataSource, setDataSource] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [currentRowId, setCurrentRowId] = useState(-1);
    const [chartData, setChartData] = useState({});
    const [criteria, setCriteria] = useState({});
    const [varid, setVarid] = useState(23)

    const { varidparam } = useParams();

    useEffect(() => {
        document.title = "АСГДМ Амур - Текущая обстановка";
        console.log(`effect varid = ${varid}`)
       
            fetch(`http://api.amur-hdm.ru/react/api/DataValue/last/${varid}`).then((response) => {
                //    fetch('http://localhost:8085/api/DataValue/last2').then((response) => {
                return response.json();
            })
                .then((body) => {

                    setDataSource(body);

                })
        
    }, [varid]
    );

    useEffect(()=>
    {
        console.log(`effect varidparam = ${varidparam}`)
        setVarid(varidparam)

    },[varidparam])


    const columns = [
        {
            title: 'Пост',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: '250px',
            className: 'table__column'
        }];

    dataSource?.Item1?.forEach(dt => {
        columns.push({
            "title": dt,
            "dataIndex": dt,
            "key": dt,
            "width": '82px',
            "className": 'table__column'
        })
    });

    return (
        <>
            <Title level={3}>{varNames[varid]}</Title>
            <Table dataSource={dataSource?.Item2}
                columns={columns}
                //scroll={{x: 1500, y: 700}}
                pagination={{ position: ['none', 'none'], defaultPageSize: 300 }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {

                            setCurrentRowId(rowIndex)
                            const { key, name, ...rowData } = dataSource.Item2[rowIndex];
                            const mappedData = Object.entries(rowData).map(([k, v]) => { return { date: k, H: +v } });
                            const siteCode = dataSource.Item2[rowIndex].key
                            const crit = graphAttr.data.find(item => item.code == siteCode)
                            //console.log('sitecode, crit', siteCode, crit)
                            setCriteria(crit);

                            //console.log(mappedData);
                            setChartData(mappedData);
                            setModalVisible(true);

                        }, // click row
                    }
                }}
            />
            <Modal
                title={currentRowId === -1 ? 'нет данных' : dataSource.Item2[currentRowId].name}
                centered
                open={isModalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                width={1000}
                footer={null}
                closable={false}
            >
                <LineChart
                    width={920} height={400} data={chartData}
                    margin={{ top: 40, right: 40, bottom: 20, left: 40 }}
                >
                    <CartesianGrid />
                    <XAxis dataKey="date" />
                    <YAxis dataKey='H' domain={[criteria?.minY, criteria?.maxY]} />
                    <Tooltip />
                    <ReferenceLine y={criteria?.green} label="Пойма" stroke="green" />
                    <ReferenceLine y={criteria?.orange} label="НЯ" stroke="orange" />
                    <ReferenceLine y={criteria?.red} label="ОЯ" stroke="red" />

                    <Line type="monotone" dataKey='H' stroke="#ff7300" />

                </LineChart>
                {/*{currentRowId === -1?  'нет данных' : JSON.stringify(chartData)};*/}
            </Modal>
        </>

    );
}

export default MeteoDataTable;