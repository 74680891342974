export default function AboutPage() {
    return (
        <div>
            <p>Состав Системы моделирования для опытной эксплуатации в 2023 году:</p>
            <ol>
                <li>программные модули импорта фактических и прогностических гидрометеорологических данных (размещение -
                    сервер ФГБУ РосНИИВХ):
                    <ul>
                        <li>GFS (Global Forecast System) – прогнозные среднесуточные температура и влажность воздуха,
                            суточные суммы осадков заблаговременностью 1-14 суток;
                        </li>
                        <li>CFS (Climate Forecast System) - прогнозные среднесуточные температура и влажность воздуха,
                            суточные суммы осадков заблаговременностью 1-60 суток;
                        </li>
                        <li>фактические метеоданные – веб-сервис Единой государственной системы информации об обстановке
                            в Мировом океане (ЕСИМО);
                        </li>
                        <li>веб-сервис ФГБУ «Дальневосточное УГМС»: фактические (оперативные на 8.00 часов) уровни воды
                            на 71 гидрологическом посту;
                        </li>
                    </ul>
                </li>
                <li>база данных хранения фактических, прогностическич гидрометеоданных, результатов моделирования
                    (размещение - сервер ФГБУ РосНИИВХ),
                </li>
                <li>веб-сервис, предоставляющий удаленный доступ к базе данных и унифицированный веб-клиент, реализующий
                    программный интерфейс OpenMI, для связи моделей с источником данных (размещение - сервер ФГБУ
                    РосНИИВХ);
                </li>
                <li>Физико-математическая модель формирования стока ECOMAG для бассейна р.Амур (размещение - сервер ИВП
                    РАН);
                </li>
                {/* <li>гидродинамическая модель распространения паводковой волны по основному руслу реки Амур (ИВП РАН,
                    2014), подключенная к системе информационного обеспечения гидрологического моделирования. В 2021
                    году расчеты по ней не выполнялись – тестовые запуски (размещение – рабочая станция Дальневосточного
                    филиала ФГБУ РосНИИВХ);
                </li> */}
                <li>одномерная гидродинамическая модель DHI MIKE11 р.Амур (от с. Покровка до г. Комсомольск-на-Амуре),
                    р. Зея (от створа Зейской ГЭС до устья), р. Уссури (от г. Лесозаводск до устья), р.Сунгари (от
                    г.Цзямусы до устья), р.Бурея (от створа Нижне-Бурейской ГЭС до устья), р.Тунгуска (от с.
                    Архангеловка до устья). Модель в разработке, размещение – рабочая станция Дальневосточного филиала
                    ФГБУ РосНИИВХ;
                </li>
                {/* <li>Двумерная гидродинамическая модель STREAM-2D (р.Зея от створа Зейской ГЭС до устья; р. Амур от с.
                    Покровка до г. Комсомольск-на-Амуре). Размещение - рабочая станция ИВП РАН.
                </li> */}
                <li>Web-сайт для ввода данных и отображения результатов расчета по моделям системы в табличном и
                    графическом виде <a href="http://amur-hdm.ru/">(http://amur-hdm.ru/)</a>. Доступ к сайту
                    предоставлен: Амурскому БВУ; ФГБУ «Дальневосточное УГМС»; ИВП РАН.
                </li>
            </ol>
        </div>
    );
}
