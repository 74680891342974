import { Radio, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import graphAttr from '../../../static/graphAttr.json'
import Modal from "antd/es/modal/Modal";
import {  LineChart, Line, XAxis, YAxis,
     CartesianGrid, ReferenceLine, 
     Tooltip} from 'recharts';
const { Title } = Typography;

function ForecastDataTable() {

    const [dataSource, setDataSource] = useState(null);
    const [model, setModel] = useState("m11");
    const [tableTitle, setTableTitle] = useState(null);

    const [isModalVisible, setModalVisible] = useState(false);
    const [currentRowId, setCurrentRowId] = useState(-1);
    const [chartData,setChartData] = useState({});
    const [criteria, setCriteria] = useState({});

    //const [currentDate, setDate] = useState(20230531);
    const emgUrl = "http://api.amur-hdm.ru/react/api/PixelForecasts/lastemg"
    const m11Url = "http://api2.amur-hdm.ru/api/ForecastValue/lastm11"

    // const emgUrl = "http://localhost:8085/api/PixelForecasts/lastemg"
    // const m11Url = "http://localhost:8085/api/ForecastValue/lastm11"

    const emgTitle = "Прогноз среднесуточного расхода в русловой сети, куб.м/сек"
    const m11Title = "Прогноз уровня воды над \"0\" поста, см"

    useEffect(() => {
        document.title = "АСГДМ Амур - Прогноз обстановки";
        //  if (!currentDate) {
        //      setDate(20230531);
        //  }

        setTableTitle(model === "emg"? emgTitle: m11Title)
        fetch(model === "emg" ? emgUrl : m11Url).then((response) => {
            //fetch(`http://localhost:8085/api/PixelForecasts/lastemg`).then((response) => {
            // console.log('fetched');
            return response.json();
        })
            .then((body) => {
                // console.log(dataSource)
                
                    // console.log('setDataSource')
                    setDataSource(body);
                
            })

    }, [model])




    // const onDateChange = (date, dateString) => {
    //     const dt = date.format('YYYYMMDD');
    //     // console.log(dt,currentDate)
    //     if (currentDate !== dt) {
    //         setDataSource(null);
    //     }

    //     setDate(dt)
    // }

    const columns = [
        {
            title: 'Пост',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: '250px',
            className: 'table__column'
        }];

    dataSource?.Item2.forEach(dt => {
        columns.push({
            "title": dt,
            "dataIndex": dt,
            "key": dt,
            "width": '70px',
            "className": 'table__column'
        })
    });

    const onModelChange = (e) => {
        setModel(e.target.value)

    }

    return (
        <div>
            {/* <DatePicker onChange={onDateChange} 
            defaultValue={dayjs(new Date())}
            allowClear = {false} /> */}
            <Radio.Group defaultValue="m11" buttonStyle="solid" onChange={onModelChange}>
                {/*<Radio.Button value="emg">ЭКОМАГ</Radio.Button>*/}
                <Radio.Button value="m11">ГДМ</Radio.Button>
            </Radio.Group>
            <Title level={3}>{tableTitle}</Title>
            <Title level={4}>Расчет от {dataSource?.Item1}</Title>
            <Table dataSource={dataSource?.Item3}
                columns={columns}
                scroll={{ x: 'max-content', y: '90%' }}
                pagination={{ position: ['none', 'none'], defaultPageSize: 100 }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            const {key,name, ... rowData} = dataSource.Item3[rowIndex];
                            setCurrentRowId(rowIndex)
                            if(model === "emg")
                            {
                                
                                const mappedData = Object.entries(rowData).map(([k,v])=>{return{date:k, Q:+v}});
                                setChartData(mappedData);
                                setCriteria(null);
                            
                            }
                            else{
                                const mappedData = Object.entries(rowData).map(([k,v])=>{return{date:k, H:+v}});
                                setChartData(mappedData);
                                const siteCode = dataSource.Item3[rowIndex].key
                                const crit = graphAttr.data.find(item=>item.code==siteCode)
                                //console.log('sitecode, crit', siteCode, crit)
                                setCriteria(crit);

                            }
                            const siteCode = dataSource.Item2[rowIndex].key
                              
                            
                            //const {key,name, ... rowData} = dataSource.Item3[rowIndex];
                            //const mappedData = Object.entries(rowData).map(([k,v])=>{return{date:k, val:+v}});
                            //console.log(mappedData);
                            
                            setModalVisible(true);

                        }, // click row
                    }
                }}
            />
             <Modal
                title={currentRowId === -1?  'нет данных' : dataSource.Item3[currentRowId].name} 
                centered
                open={isModalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                width={1000}
                footer={null}
                closable={false}
            >
                <LineChart
                    width={920} height={400} data={chartData}
                    margin={{ top: 40, right: 40, bottom: 20, left: 40 }}
                >
                    <CartesianGrid  />
                    <XAxis dataKey="date" />
                    <YAxis dataKey={model==="emg"?"Q":"H"} domain={model==="emg"?['auto', 'auto']:[criteria?.minY, criteria?.maxY]} />
                    <Tooltip/>
                    <ReferenceLine y={criteria?.green} label="Пойма" stroke="green" />
                    <ReferenceLine y={criteria?.orange} label="НЯ" stroke="orange" />
                    <ReferenceLine y={criteria?.red} label="ОЯ" stroke="red" />


                    <Line type="monotone" dataKey={model==="emg"?"Q":"H"} stroke="#ff7300" />

                </LineChart>
                {/*{currentRowId === -1?  'нет данных' : JSON.stringify(chartData)};*/}
            </Modal>
        </div>
    )
}

export default ForecastDataTable;