import './Header.css';
import {Link, useLocation} from 'react-router-dom';
import {Menu} from "antd";
import 'antd/dist/reset.css';
import {DashboardOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../storage/reducers/user";

const {SubMenu} = Menu;

function Header() {
    const location = useLocation();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    //console.log('menuuser', user);
    const [previewEnabled, setPreviewEnabled] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false);

    function handleLogout() {
        dispatch(logout())
    }

    useEffect(() => {
        if (user?.roles) {
            setPreviewEnabled(user?.roles?.includes('view'));
            setEditEnabled(user?.roles?.includes('edit'));
        }
        else{
            setPreviewEnabled(false);
            setEditEnabled(false);

        }

    }, [user]);


    return (
        <div className={'header-container'}>
            {/*<h1 className={'h1'}>АВТОМАТИЗИРОВАННАЯ СИСТЕМА ГИДРОЛОГИЧЕСКОГО МОДЕЛИРОВАНИЯ В БАССЕЙНЕ Р. АМУР</h1>*/}

            <Menu mode="horizontal" defaultSelectedKeys={[location.pathname]} className={'top-menu'}>
                <Menu.Item key={'/'} disabled={!previewEnabled}>
                    <DashboardOutlined/>
                    <Link to={'observation'}>Текущая обстановка</Link>

                    </Menu.Item>
                                        {editEnabled?
                <SubMenu title={'Метео'} key={'888'} disabled={!editEnabled}>
                    <Menu.Item key={'/observation/23'}> <Link
                        to={'observation/23'}>Осадки</Link></Menu.Item> 
                    <Menu.Item key={'observation/10'}> <Link to={'observation/10'}>T</Link></Menu.Item>
                    <Menu.Item key={'observation/83'}> <Link to={'observation/83'}>Дефицит</Link></Menu.Item>
                  
                </SubMenu>:<></>}
                

                <Menu.Item key={'/forecast'} disabled={!previewEnabled}>
                    <Link to={'forecast'}>Прогноз обстановки</Link>
                </Menu.Item>
                {editEnabled?
                <SubMenu title={'Прогноз притока'} key={'3'} disabled={!editEnabled}>
                    <Menu.Item key={'/forecast-inflow/5012'}> <Link
                        to={'forecast-inflow/5012'}>Хабаровск</Link></Menu.Item> 
                    <Menu.Item key={'/forecast-inflow/ZRT'}> <Link to={'forecast-inflow/ZRT'}>Зейское
                        водохранилище</Link></Menu.Item>
                    <Menu.Item key={'forecast-inflow/BRT'}> <Link to={'forecast-inflow/BRT'}>Бурейское
                        водохранилище</Link></Menu.Item>
                </SubMenu>:<></>}
               <SubMenu title={'Режим работы водохранилищ'} key={'4'} disabled={!previewEnabled}>
                    <Menu.Item key={'/reservoir/BR'}>
                        <Link to={'/reservoir/BR'}>Бурейское</Link>
                    </Menu.Item>
                    <Menu.Item key={'/reservoir/NBR'}>
                        <Link to={'/reservoir/NBR'}>Нижне-Бурейское</Link>
                    </Menu.Item> 
                     <Menu.Item key={'/reservoir/ZR'}>
                        <Link to={'/reservoir/ZR'}>Зейское</Link>
                    </Menu.Item> 
                   {/*} <Menu.Item key={'/dispatchChart'}>
                    <Link to={'/dispatchChart'}>Диспетчерский график</Link> 
                </Menu.Item>*/}
            </SubMenu>
                <SubMenu title={'Информация'} key={'info'} >
                    <Menu.Item key={'/static/about'}>
                        <Link to={'/static/about'}>О проекте</Link>
                    </Menu.Item>
                    <Menu.Item key={'/static/contacts'}>
                        <Link to={'/static/contacts'}>Контакты</Link>
                    </Menu.Item>
                </SubMenu>

                {/* <Menu.Item key={'loadaata'}>
                    <Link to={'/loaddata/ZR'}>Загрузить данные Зея</Link>
                  </Menu.Item> */}

                <SubMenu title={'Уч. запись'} key={'5'}>
                    <Menu.Item key={'/login'} disabled={previewEnabled || editEnabled}>
                        <Link to={'/login'}>
                            {user?.roles ? 'roles : ' + user?.roles : 'Вход'}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={'/logout'} disabled={!previewEnabled && !editEnabled}>
                        <a onClick={handleLogout}> Выход</a>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </div>
    );
}

export default Header;