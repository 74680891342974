import {  Button, Input, Form  }  from "antd";
import {  useState, useEffect} from  "react";

import {useParams} from "react-router-dom";
const { TextArea } = Input;

function LoadData(){

    
    const [form] = Form.useForm();
    const [currentSiteCode, setSiteCode] = useState(0);
    const [txt, setTxt] = useState("");
    const {siteCode} = useParams();
    useEffect(() => {
        if (siteCode !== currentSiteCode) {
            //console.log('setSiteCode', siteCode)
            setSiteCode(siteCode);
            
        }
    },
    [siteCode, currentSiteCode])
    
    function parseAndSend(){
        //const txt = form.getFieldValue("textField")
        const lines = txt.split(/\r?\n/).filter(element => element)
        .map(el => el.split(/\t/))
        .map((el1) => { return {dt : el1[0], hh : el1[1], q_out : el1[2], q_in : el1[3]} })
        
        console.log(lines)

        const data = fetch("http://localhost:8085/api/ReservoirValues", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({id:currentSiteCode, data:lines}),
            }).then((response) => {
                return response.json();
              }).then(data => {
                console.log(data);
            });

    }

    return (<div>
        <p>{currentSiteCode}</p>
        <Form form={form} onFinish={parseAndSend}>        
        <TextArea name="textField" 
        onChange={(e) => setTxt(e.target.value)}
        placeholder="yyyy-MM-dd;H_в_б(м БС77);Q_сум_сброс(м3/c);Q_приток(м3/с)" autoSize/>
        <Button onClick={parseAndSend}>save</Button>
       
        </Form>
    </div>);
}

export default LoadData