import './LoginForm.css'
import {getUser, logout} from "../../storage/reducers/user";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {  Button, Checkbox, Form, Input, message  } from "antd";
import {  LockOutlined, UserOutlined  } from "@ant-design/icons";

function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/';


    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        if (user?.login) {
            navigate(fromPage, {replace: true});
        }
        // else{
        //   message.error('Login failed!');
        // }
    }, [user])


    let login = '';
    let password = '';

    const onFinish = (values) => {
        //console.log('Received values of form: ', values);
        if (values.username && values.password) {
                     dispatch(getUser({login: values.username, 
                        password: values.password, 
                        needRemember: values.remember}))
                        
                     console.log(user);
                    //  if(!user.login)
                    //  {
                    //     message.error('Submit failed!');
                    //  }
                    //  else{
                    //  navigate(fromPage, {replace: true});
                    // }
        }
      };

    // function validate_form() {
    //     const inpLogin = document.getElementById('login-input');
    //     const lblLoginValidationResult = document.getElementById('lb-login-validation-result');


    //     const inpPassword = document.getElementById('password-input');
    //     const lblPasswordValidationResult = document.getElementById('lbl-password-validation-result');

      

    //     const inpRemember = document.getElementById('remeber-checkbox-input');

    //     if (login && password) {
    //         dispatch(getUser({login: login, password: password, needRemember: inpRemember.checked}))
    //         console.log(fromPage);
    //         navigate(fromPage, {replace: true});

    //     } else {

    //         //decorateForm();
    //     }

    // }

   

    // function handleLogout() {
    //     dispatch(logout())
    //
    // }

    return (
       
           <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
       
      </Form.Item>
    </Form>
        

    );
};


export default LoginForm;